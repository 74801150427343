// modules
import React from 'react'
import { graphql } from 'gatsby'
// components
import Grid from '../components/shared/Grid/Grid'
import Card from '../components/shared/Card/Card'
import SEO from '../components/shared/SEO/SEO'

// definitions
function ExhibitionsPage({ data, location }) {
  const pageRoute = location.pathname
  const pageTitle = 'Past Exhibitions'
  const exhibitions = data.allMarkdownRemark.edges

  return (
    <>
      <SEO title={pageTitle} route={pageRoute} />
      <h1>{pageTitle}</h1>
      {exhibitions.length ? (
        <Grid layout="fixed">
          {exhibitions.map(exhibition => {
            const {
              node: { id, frontmatter },
            } = exhibition

            const city = frontmatter.city
            const location = frontmatter.location

            if (city) {
              return (
                <Card key={id}>
                  <h3>{city}</h3>
                  <p>{location}</p>
                </Card>
              )
            } else {
              return null
            }
          })}
        </Grid>
      ) : null}
    </>
  )
}

// exports
export default ExhibitionsPage

// query
export const exhibitionsPageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "./content/exhibitions/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            city
            location
          }
        }
      }
    }
  }
`
