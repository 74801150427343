// modules
import PropTypes from 'prop-types'
import React from 'react'
// styles
import styles from './Grid.module.scss'

// definitions
function Grid({ children, layout }) {
  if (children) {
    const layoutClassName = () => {
      switch (layout) {
        case 'fluid':
          return styles.fluid
        case 'fixed':
          return styles.fixed
        default:
          break
      }
    }
    return (
      <ul className={layoutClassName()}>
        {React.Children.map(children, child => {
          return child ? <li>{child}</li> : null
        })}
      </ul>
    )
  } else {
    return null
  }
}

// props type checking
Grid.propTypes = {
  layout: PropTypes.oneOf(['fluid', 'fixed']).isRequired,
}

// exports
export default Grid
