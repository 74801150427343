// modules
import PropTypes from 'prop-types'
import React from 'react'
// styles
import styles from './Card.module.scss'

// definitions
function Card({ children, url }) {
  if (url) {
    return (
      <a
        className={styles.link}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.Card}>{children}</div>
      </a>
    )
  } else {
    return <div className={styles.Card}>{children}</div>
  }
}

// props type checking
Card.propTypes = {
  url: PropTypes.string,
}

// exports
export default Card
